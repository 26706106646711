import './Login.scss';

import React, { useState } from 'react';
import { BackgroundLogin, ContainerLogin } from '../../components/Container';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { login } from '../../services/AuthService';
import { Message } from 'primereact/message';


type Inputs = {
    username: string;
    password: string;
  };

const Login = () => {
    const [apiError, setApiError] = useState('');
    
    const {
        control,
        handleSubmit,
      } = useForm<Inputs>({
        defaultValues: {
            username: "",
            password: ""
        }
      });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        const response = await login(data.username, data.password);
        console.log(response);
        if(response.status === 400){
            setApiError(response.data.mensaje);
        }
    }

    return (
        <BackgroundLogin>
            <ContainerLogin>
                <p className='header-login'>Inicio de Sesión</p>
                {apiError && <Message 
                className='mt-5' severity='error' text={apiError}/>}
                <form className='login-form'
                    onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="username"
                        control={control}
                        rules={{
                            required: "El usuario es requerido.",
                        }}
                        render={({ field, fieldState }) => (
                            <div className="login-field field">
                                <label htmlFor={field.name}>
                                    Usuario
                                </label>
                                <InputText
                                    id={field.name}
                                    value={field.value}
                                    className={classNames({ "p-invalid": fieldState.error }, 'login-input')}
                                    onChange={(e) => field.onChange(e)}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        rules={{
                            required: "La clave es requerida.",
                        }}
                        render={({ field, fieldState }) => (
                            <div className="login-field field">
                                <label htmlFor={field.name}>
                                    Clave
                                </label>
                                <Password
                                    id={field.name}
                                    value={field.value}
                                    className={classNames({ "p-invalid": fieldState.error }, 'login-input')}
                                    onChange={(e) => field.onChange(e)}
                                    inputStyle={{width: '100%'}}
                                    toggleMask
                                    feedback={false}
                                />
                            </div>
                        )}
                    />
                    <Button label='Iniciar Sesión' className='login-button'/>
                </form>
            </ContainerLogin>
        </BackgroundLogin>
    );
};

export default Login;