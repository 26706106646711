import React, { FC } from "react";
import { IIngresoSensores } from "./Liquidaciones.type";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { formatDateForPost } from "../../util/DateUtil";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { ingresarSensores } from "../../redux/liquidaciones";

interface Inputs {
  ingresos: IIngresoSensores[];
}

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const IngresarSensores: FC<Props> = ({
  visible,
  setVisible,
}) => {

  const dispatch = useAppDispatch();
  const { listaBuses: buses } = useAppSelector((state) => state.buses);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<Inputs>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "ingresos",
  });

  const getFormErrorMessage = (name: any) => {
    return errors[name as keyof Inputs] ? (
      <small className="p-error block">
        {errors[name as keyof Inputs]?.message}
      </small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const ingresos = data.ingresos;
    ingresos.forEach((ingreso, index) => {
      ingreso.fecha = formatDateForPost(ingreso.fecha as Date);
    });
    
    dispatch(ingresarSensores(ingresos, setVisible));
  };

  const changeFecha = (value: Date, indexInput: number) => {
    // Update the fecha at the specific index
    setValue(`ingresos.${indexInput}.fecha`, value);
    // Update subsequent indices
    for (let i = indexInput + 1; i < fields.length; i++) {
      setValue(`ingresos.${i}.fecha`, value);
    }
  }


  return (
    <Dialog
      header="Formulario Ingreso Sensores"
      visible={visible}
      onHide={() => setVisible(false)}
      style={{ width: "80vw", height: "75vh" }}
    >
      <form
        id="form_liquidacion"
        className="formgrid grid"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="col-12 surface-200 px-5" id="gastos_block">
          <div className="flex justify-content-start my-5" id="gastos_actions">
            <Button
              className="mr-3"
              severity="info"
              onClick={() =>
                append({
                  fecha: undefined,
                  busNumero: "",
                  sensor: 0,
                  viajes: 0,
                  civica: 0
                })
              }
              label="Agregar Sensor"
              raised
              type="button"
            />
            <Button
              className=""
              severity="danger"
              onClick={() => remove()}
              label="Limpiar Sensores"
              raised
              type="button"
            />
          </div>
          {fields.map((item, index) => (
            <div className="flex flex-wrap justify-content-between" key={item.id}>
              <Controller
                name={`ingresos.${index}.fecha`}
                control={control}
                rules={{ required: "Fecha es requerida." }}
                render={({ field, fieldState }) => (
                  <div className="field">
                    <label htmlFor={field.name} className="block">
                      Fecha
                    </label>
                    <Calendar
                      inputId={field.name}
                      value={field.value}
                      onChange={e => changeFecha(e.value as Date, index)}
                      dateFormat="dd/mm/yy"
                      className={classNames({ "p-invalid": fieldState.error })}
                      showIcon
                    />
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
              <Controller
                name={`ingresos.${index}.busNumero`}
                control={control}
                rules={{ required: "Bus es requerido." }}
                render={({ field, fieldState }) => (
                  <div className="field">
                    <p className="block" style={{marginBottom: '0.5rem'}}>
                      Bus
                    </p>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      placeholder="Seleccione un bus"
                      options={buses}
                      optionLabel="numero"
                      optionValue="numero"
                      focusInputRef={field.ref}
                      onChange={(e) => field.onChange(e.value)}
                      className={classNames({ "p-invalid": fieldState.error })}
                    />
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
              <Controller
                name={`ingresos.${index}.sensor`}
                control={control}
                rules={{
                  required: "El sensor es requerido.",
                  validate: (value) =>
                    value! > 0 || "Ingrese un número valido de sensor.",
                }}
                render={({ field, fieldState }) => (
                  <div className="field">
                    <label htmlFor={field.name} className="block">
                      Sensor
                    </label>
                    <InputNumber
                      id={field.name}
                      inputRef={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e.value)}
                      useGrouping={false}
                      className={classNames({
                        "p-invalid": fieldState.error,
                      })}
                    />
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
              <Controller
                name={`ingresos.${index}.civica`}
                control={control}
                rules={{
                  validate: (value) =>
                    value! >= 0 || "Ingrese un número valido de civica.",
                }}
                render={({ field, fieldState }) => (
                  <div className="field">
                    <label htmlFor={field.name} className="block">
                      Civica
                    </label>
                    <InputNumber
                      id={field.name}
                      inputRef={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e.value)}
                      useGrouping={false}
                      inputClassName={classNames({
                        "p-invalid": fieldState.error,
                      })}
                    />
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />   
              <Controller
                name={`ingresos.${index}.viajes`}
                control={control}
                rules={{
                  required: "Los viajes son requeridos.",
                  validate: (value) =>
                    value! > 0 || "Ingrese un número valido de viajes.",
                }}
                render={({ field, fieldState }) => (
                  <div className="field">
                    <label htmlFor={field.name} className="block">
                      Viajes
                    </label>
                    <InputNumber
                      id={field.name}
                      inputRef={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e.value)}
                      useGrouping={false}
                      inputClassName={classNames({
                        "p-invalid": fieldState.error,
                      })}
                    />
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
              <div className="flex align-items-center">
                <Button
                  type="button"
                  severity="danger"
                  onClick={() => remove(index)}
                  icon="pi pi-times"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="col-12">
          <Button
            className="mt-5 ml-2"
            label="Ingresar"
            type="submit"
            icon="pi pi-check"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default IngresarSensores;
