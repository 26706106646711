import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { IPasaje } from '../../features/pasajes/Pasajes.type';
import { mostrarToast } from '../toast';
import { IMessage } from '../../shared/Message.type';
import { axiosInstance } from '../../util/AxiosInterceptor';


export interface PasajeInitialState {
    pasaje: IPasaje,
    listaPasajes: IPasaje[],
    loading: boolean,
    error: string | undefined
}

const initialState: PasajeInitialState = {
    pasaje: {},
    listaPasajes: [],
    loading: true,
    error: undefined
}

export const pasajesSlice = createSlice({
    name : 'pasajes',
    initialState,
    reducers: {
        setPasaje: (state, action) =>{
            state.pasaje = action.payload;
        },
        setListaPasajes : (state, action) => {
            state.listaPasajes = action.payload;
        },
        setLoading : (state, action) => {
            state.loading = action.payload;
        },
        setError : (state, action) => {
            state.error = action.payload;
        },
        pushToListaPasajes: (state, action) => {
            state.listaPasajes.push(action.payload);
        },
        editItemListaPasaje: (state, action) => {
            state.listaPasajes = state.listaPasajes.map(item => item.uuid === 
                action.payload.uuid ? action.payload : item);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPasajes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchPasajes.fulfilled, (state, action) => {
                state.listaPasajes = action.payload;
                state.loading = false;
            })
            .addCase(fetchPasajes.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    }
});

export const { setPasaje, setListaPasajes, 
    setLoading, setError, pushToListaPasajes, editItemListaPasaje } = pasajesSlice.actions;

export default pasajesSlice.reducer;

export const fetchPasajes = createAsyncThunk('pasajes/fetchPasajes', async () => {
    const response = await axiosInstance.get('/pasajes');
    return response.data;
});

export const createPasaje = (payload: IPasaje, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .post("/pasajes ", payload)
    .then((response) => {
        const pasaje = response?.data as IPasaje;
        dispatch(pushToListaPasajes(pasaje));
        setVisible(false);
        dispatch(mostrarToast({severity: 'success', summary: '', detail: 'Pasaje creado existosamente!'}))
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const invalidarPasajeAction = (payload: string, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .put('/pasajes/invalidarPasaje', null, { params: { uuidPasaje: payload}})
    .then((response) => {
        const mensaje = response?.data as IMessage;
        setVisible(false);
        dispatch(mostrarToast({severity: 'success', summary: '', detail: mensaje.mensaje}))
    })
    .catch((error) => {
        dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const editarPasaje = (payload: IPasaje, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .put("/pasajes ", payload)
    .then((response) => {
        const pasaje = response?.data as IPasaje;
        dispatch(editItemListaPasaje(pasaje));
        setVisible(false);
        dispatch(mostrarToast({severity: 'success', summary: '', detail: 'Pasaje editado existosamente!'}))
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};