import React, { useEffect, useState } from 'react';
import { Container } from '../../components/Container';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { IPasaje } from './Pasajes.type';
import CrearPasaje from './CrearPasaje';
import { classNames } from 'primereact/utils';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { fetchPasajes, invalidarPasajeAction } from '../../redux/pasajes';
import ModalConfirmacion from '../../components/ModalConfirmacion';
import EditarPasaje from './EditarPasaje';

const ListaPasajes = () => {

    const dispatch = useAppDispatch();
    const { listaPasajes, loading } = useAppSelector((state) => state.pasajes)

    const [visibleCrear, setVisibleCrear] = useState(false);

    const [visibleEditar, setVisibleEditar] = useState(false);

    const [visibleInvalidar, setVisibleInvalidar] = useState(false);

    const [pasaje, setPasaje] = useState<IPasaje>({});

    useEffect(() => {
        dispatch(fetchPasajes());
    }, [dispatch]);

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Crear" icon="pi pi-plus" severity="success" onClick={() => setVisibleCrear(true)}/>
                <Button label="Editar" icon="pi pi-pencil" severity="info" disabled={Object.keys(pasaje).length === 0} onClick={() => setVisibleEditar(true)}/>
                <Button label="Invalidar" icon="pi pi-trash" severity="danger" disabled={Object.keys(pasaje).length === 0} onClick={() => setVisibleInvalidar(true)} />
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <Button label="Exportar" icon="pi pi-upload" className="p-button-help"/>;
    };

    const validBodyTemplate = (rowData: IPasaje) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.valido, 'text-red-500 pi-times-circle': !rowData.valido })}></i>;
    };

    const rowClass = (data: IPasaje) => {
    return {
        'bg-red-500': data.valido === false || data.valido === null
    };
    };

    const rutaBodyTemplate = (rowData: IPasaje) => {
        let rutaNombre: string;
        switch(rowData.ruta) {
            case "CCNT": 
                rutaNombre = "Circular Coonatra";
                break;
            case "CSUR3":
                rutaNombre = "Circular Sur 303";
                break;
            case "CSUR2":
                rutaNombre = "Circular Sur 302";
                break;
            case "CBOS":
                rutaNombre = "Calasanz Boston";
                break;
            default:
                rutaNombre = "Circular Sur 303";
        }
        return rutaNombre;
    };
    

    const invalidarPasaje = () : void => {
        dispatch(invalidarPasajeAction(pasaje.uuid!, setVisibleInvalidar));
    }

    return (
        <Container>
            <Toolbar className="mb-3" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

            <DataTable value={listaPasajes} paginator rows={10} dataKey="uuid" loading={loading} emptyMessage="No hay pasajes."
                onSelectionChange={(e) => setPasaje(e.value)} selectionMode="single" selection={pasaje} showGridlines rowClassName={rowClass}
            >
                <Column field="uuid" header="UUID" hidden/>
                <Column field="ruta" header="Ruta" style={{ minWidth: '6rem' }} body={rutaBodyTemplate}/>
                <Column field="valor" header="Valor Pasaje" style={{ minWidth: '6rem' }} />
                <Column field="porcentajePasajero" header="Porcentaje" style={{ minWidth: '6rem' }}/>
                <Column field="porcentajeFestivos" header="Festivos" style={{ minWidth: '6rem' }} />
                <Column field="porcentajeBonificacion" header="Bonificación" style={{ minWidth: '6rem' }} />
                <Column field="limiteBonificacion" header="Limite Bonificación" style={{ minWidth: '6rem' }} />
                <Column field="pasajerosPorViaje" header="Pasajeros Viaje" style={{ minWidth: '6rem' }} />
                <Column field="fechaInicio" header="Fecha Inicio" style={{ minWidth: '6rem' }} />
                <Column field="fechaFinal" header="Fecha Final" style={{ minWidth: '6rem' }} />
                <Column field="valido" header="Valido" style={{ minWidth: '6rem' }} className='text-center' body={validBodyTemplate}/>
            </DataTable>

            {visibleCrear && 
                <CrearPasaje visible={visibleCrear} setVisible={setVisibleCrear} />
            }
            {visibleEditar && 
                <EditarPasaje visible={visibleEditar} setVisible={setVisibleEditar} pasaje={pasaje} />
            }
            {visibleInvalidar &&
                <ModalConfirmacion
                    visible={visibleInvalidar}
                    header='Invalidar Pasaje'
                    body='¿Estás seguro que deseas invalidar  el pasaje?' 
                    setVisible={setVisibleInvalidar}
                    action={invalidarPasaje}
                />
            }
        </Container>
    );
};

export default ListaPasajes;