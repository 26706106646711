import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import React, { FC } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { IPasaje } from "./Pasajes.type";
import { InputNumber } from "primereact/inputnumber";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { editarPasaje } from "../../redux/pasajes";
import { Calendar } from "primereact/calendar";
import { formatDateForPost } from "../../util/DateUtil";

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  pasaje: IPasaje;
}

type Inputs = {
  ruta: string;
  fechaInicio: Date | string;
  fechaFinal: Date | string;
  valor: number;
  porcentajePasajero: number;
  porcentajeFestivos: number;
  porcentajeBonificacion: number;
  limiteBonificacion: number;
  pasajerosPorViaje: number;
};

const EditarPasaje: FC<Props> = ({ visible, setVisible, pasaje }) => {
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues : {
      ruta: pasaje.ruta,
      valor: pasaje.valor,
      porcentajePasajero: pasaje.porcentajePasajero,
      porcentajeFestivos: pasaje.porcentajeFestivos,
      porcentajeBonificacion: pasaje.porcentajeBonificacion,
      limiteBonificacion: pasaje.limiteBonificacion,
      pasajerosPorViaje: pasaje.pasajerosPorViaje,
      fechaInicio: pasaje.fechaInicio ? new Date(pasaje.fechaInicio) : new Date(),
      fechaFinal: pasaje.fechaFinal ? new Date(pasaje.fechaFinal) : new Date()
    }
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const pasajeEditado = data as IPasaje;
    pasajeEditado.fechaInicio = formatDateForPost(pasajeEditado.fechaInicio ? new Date(pasajeEditado.fechaInicio) : new Date());
    pasajeEditado.fechaFinal = formatDateForPost(pasajeEditado.fechaFinal ? new Date(pasajeEditado.fechaFinal) : new Date());
    pasajeEditado.uuid = pasaje.uuid;
    dispatch(editarPasaje(pasajeEditado, setVisible));
  };

  const rutas = [
    {
      codigo: "CCNT",
      descripcion: "Circular Coonatra",
    },
    {
      codigo: "CSUR3",
      descripcion: "Circular Sur 303",
    },
    {
      codigo: "CSUR2",
      descripcion: "Circular Sur 302",
    },
    {
      codigo: "CBOS",
      descripcion: "Calasanz Boston",
    },
];

  const getFormErrorMessage = (name: any) => {
    return errors[name as keyof Inputs] ? (
      <small className="p-error block">
        {errors[name as keyof Inputs]?.message}
      </small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <Dialog
      header="Formulario Pasaje"
      visible={visible}
      onHide={() => setVisible(false)}
      style={{width: "40vw", maxWidth: "40rem"  }}
    >
      <form
        id="form_liquidacion"
        className="formgrid grid"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="ruta"
          control={control}
          rules={{ required: "La ruta es requerida." }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <p className="block" style={{marginBottom: '0.5rem'}}>
                Ruta
              </p>
              <Dropdown
                id={field.name}
                value={field.value}
                placeholder="Seleccione una ruta"
                options={rutas}
                optionLabel="descripcion"
                optionValue="codigo"
                focusInputRef={field.ref}
                onChange={(e) => field.onChange(e.value)}
                className={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="fechaInicio"
          control={control}
          rules={{ required: "Fecha Inicial es requerida." }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Fecha Inicial
              </label>
              <Calendar
                inputId={field.name}
                value={field.value}
                onChange={field.onChange}
                dateFormat="dd MM yy"
                className={classNames({ "p-invalid": fieldState.error })}
                showIcon
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="fechaFinal"
          control={control}
          rules={{ required: "Fecha Final es requerida." }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Fecha Final
              </label>
              <Calendar
                inputId={field.name}
                value={field.value}
                onChange={field.onChange}
                dateFormat="dd MM yy"
                className={classNames({ "p-invalid": fieldState.error })}
                showIcon
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="valor"
          control={control}
          rules={{
            required: "El valor es requerido.",
            validate: (value) =>
              value >= 0 || "Ingrese un número valido."
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Valor del Pasaje
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                useGrouping={false}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="porcentajePasajero"
          control={control}
          rules={{
            required: "El porcentaje es requerido.",
            validate: (value) =>
              value >= 0 || "Ingrese un número valido."
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Porcentaje del Salario
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                useGrouping={false}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="porcentajeFestivos"
          control={control}
          rules={{
            required: "Porcentaje de festivos es requerido.",
            validate: (value) =>
              value >= 0 || "Ingrese un número valido."
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
              Porcentaje de Festivos
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                useGrouping={false}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="porcentajeBonificacion"
          control={control}
          rules={{
            required: "La bonificación es requerido.",
            validate: (value) =>
              value >= 0 || "Ingrese un número valido."
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
              Porcentaje de Bonificación
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                useGrouping={false}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="limiteBonificacion"
          control={control}
          rules={{
            required: "El limite de bonificación es requerido.",
            validate: (value) =>
              value >= 0 || "Ingrese un número valido."
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
              Limite de Bonificación
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                useGrouping={false}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="pasajerosPorViaje"
          control={control}
          rules={{
            required: "Los pasajeros por viaje son requeridos.",
            validate: (value) =>
              value >= 0 || "Ingrese un número valido."
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
              Pasajeros por viaje
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                useGrouping={false}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="col-12">
          <Button
            className="mt-5 ml-2"
            label="Confirmar"
            type="submit"
            icon="pi pi-check"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default EditarPasaje;
