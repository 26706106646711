import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { IBus } from '../../features/buses/Buses.type';
import { mostrarToast } from '../toast';
import { axiosInstance } from '../../util/AxiosInterceptor';


export interface BusInitialState {
    bus: IBus,
    listaBuses: IBus[],
    loading: boolean,
    error: string | undefined
}

const initialState: BusInitialState = {
    bus: {},
    listaBuses: [],
    loading: true,
    error: undefined
}

export const busesSlice = createSlice({
    name : 'buses',
    initialState,
    reducers: {
        setBus: (state, action) =>{
            state.bus = action.payload;
        },
        setListaBuses : (state, action) => {
            state.listaBuses = action.payload;
        },
        setLoading : (state, action) => {
            state.loading = action.payload;
        },
        setError : (state, action) => {
            state.error = action.payload;
        },
        pushToListaBuses: (state, action) => {
            state.listaBuses.push(action.payload);
        },
        editItemListaBuses: (state, action) => {
            state.listaBuses = state.listaBuses.map(item => item.numero === 
                action.payload.numero ? action.payload : item);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBuses.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchBuses.fulfilled, (state, action) => {
                state.listaBuses = action.payload;
                state.loading = false;
            })
            .addCase(fetchBuses.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    }
});

export const { setBus, setListaBuses, 
    setLoading, setError, pushToListaBuses,
    editItemListaBuses } = busesSlice.actions;

export default busesSlice.reducer;

export const fetchBuses = createAsyncThunk('buses/fetchBuses', async () => {
    const response = await axiosInstance.get('/buses');
    return response.data;
});

export const createBus = (payload: IBus, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .post("/buses ", payload)
    .then((response) => {
        const bus = response?.data as IBus;
        dispatch(pushToListaBuses(bus));
        setVisible(false);
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const editarBus = (payload: IBus, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .put("/buses/bus/actualizar", payload, {
        params: {
            numero: payload.numero
        }
    })
    .then((response) => {
        const busEditado = response?.data as IBus;
        dispatch(editItemListaBuses(busEditado));
        setVisible(false);
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
}