import { Button } from "primereact/button";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";

export const filterClearTemplate = (options: any) => {
    return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} severity="secondary"></Button>;
};

export const filterApplyTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} severity="success"></Button>;
};


export const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return <Calendar value={options.value} onChange={(e: CalendarChangeEvent) => options.filterCallback(e.value, options.index)} dateFormat="mm-dd-yy" placeholder="mm-dd-yyyy" mask="99-99-9999" />;
};

export const valorFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
};

export const booleanFilterTemplate = (options: ColumnFilterElementTemplateOptions, label: string) => {
    return (
        <div className="flex align-items-center gap-2">
            <label htmlFor={label + 'filter'} className="font-bold">
                {label}
            </label>
            <TriStateCheckbox id={label + 'filter'} value={options.value} onChange={(e) => options.filterCallback(e.value)} />
        </div>
    )
};

export const numericDelimiterTemplate = (data: number) => {
    return data.toLocaleString('es');
};