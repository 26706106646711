import React, { useEffect } from 'react';
import { Container } from '../../components/Container';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { fetchSalarios } from '../../redux/salarios';
import { Tooltip } from 'primereact/tooltip';
import { validateFechas } from '../../util/DateUtil';
import { fetchConductores } from '../../redux/conductores';

interface Inputs {
    conductorDocumento: string ;
    fechaInicio: Date | string ;
    fechaFinal: Date | string ;
}


const ListaSalarios = () => {
    const dispatch = useAppDispatch();
    const { tablaSalarios } = useAppSelector((state) => state.salarios);


    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
      } = useForm<Inputs>(
        {
          defaultValues: {
            conductorDocumento: '',
            fechaInicio: '',
            fechaFinal: ''
          }
        }
      );

    const { listaConductores } = useAppSelector((state) => state.conductores);

    const getFormErrorMessage = (name: any) => {
        return errors[name as keyof Inputs] ? (
            <Tooltip 
                target={"#"+name}
                position='bottom'
            >
            {errors[name as keyof Inputs]?.message}
            </Tooltip>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };

    useEffect(() => {
        if(listaConductores.length === 0){
            dispatch(fetchConductores());
        }
    }, [dispatch, listaConductores])

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        validateFechas(data.fechaInicio, data.fechaFinal);
        dispatch(fetchSalarios(data));
    }

    const leftToolbarTemplate = () => {
        return (
            
            <form 
                id="form_salarios"
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-wrap gap-2 align-items-center"
            >
                <Controller
                name="conductorDocumento"
                control={control}
                render={({ field, fieldState }) => (
                    <div className="field">
                    <p className="block" style={{marginBottom: '0.5rem'}}>
                        Conductor
                    </p>
                    <Dropdown
                        id={field.name}
                        value={field.value}
                        optionLabel="nombre"
                        optionValue="documento"
                        placeholder="Seleccione un conductor"
                        options={listaConductores}
                        focusInputRef={field.ref}
                        showClear
                        onChange={(e) => field.onChange(e.value)}
                        className={classNames({ "p-invalid": fieldState.error })}
                    />
                    {getFormErrorMessage(field.name)}
                    </div>
                )}
                />
                <Controller
                    name="fechaInicio"
                    control={control}
                    rules={{}}
                    render={({ field, fieldState }) => (
                        <div className="field">
                        <label htmlFor={field.name} className="block">
                            Fecha Inicio
                        </label>
                        <Calendar
                            inputId={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            dateFormat="dd/mm/yy"
                            className={classNames({ "p-invalid": fieldState.error })}
                            showIcon
                            showButtonBar
                        />
                        {getFormErrorMessage(field.name)}
                        </div>
                    )}
                />
                <Controller
                    name="fechaFinal"
                    control={control}
                    rules={{}}
                    render={({ field, fieldState }) => (
                        <div className="field">
                        <label htmlFor={field.name} className="block">
                            Fecha Final
                        </label>
                        <Calendar
                            inputId={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            dateFormat="dd/mm/yy"
                            className={classNames({ "p-invalid": fieldState.error })}
                            showIcon
                            showButtonBar
                        />
                        {getFormErrorMessage(field.name)}
                        </div>
                    )}
                />
                <div>
                    <Button label="Limpiar" severity="info" className='mt-3 ml-5' onClick={() => reset()}/>
                    <Button label="Buscar" severity="success" className='mt-3 ml-5'/>
                </div>
            </form>
        );
    };

    const rightToolbarTemplate = () => {
        return <Button label="Exportar" icon="pi pi-upload" className="p-button-help"/>;
    };

    const renderFooter = () => {
        return (
            <div className="flex flex-column">
                <div className="flex flex-wrap justify-content-between">
                    <p>Total de Salarios: {tablaSalarios.totalSalarios}</p>
                    <p>Promedio de salario: {tablaSalarios.promedioSalario}</p>
                    {tablaSalarios.promedioSalarioDia && <p>Promedio Día: {tablaSalarios.promedioSalarioDia}</p>}
                </div>
                <div className="flex flex-wrap justify-content-between">
                    <p>Total de Salarios Registradora: {tablaSalarios.totalSalariosRegistradora}</p>
                    <p>Promedio de Salario Registradora: {tablaSalarios.promedioSalarioRegistradora}</p>
                    {tablaSalarios.promedioSalarioDiaRegistradora && <p>Promedio Día Registradora: {tablaSalarios.promedioSalarioDiaRegistradora}</p>}
                </div>
            </div>
        );
    };

    return (
        <Container>
            <Toolbar className="mb-3" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>

            <DataTable value={tablaSalarios.salarios} paginator rows={10} dataKey="uuid" loading={false} emptyMessage="No hay salarios." showGridlines
            footer={renderFooter}
            >
                <Column field="valor" header="Salario" dataType='number' style={{ minWidth: '8rem' }} />
                <Column field="valorRegistradora" header="Salario Registradora" dataType='number' style={{ minWidth: '8rem' }} />
                <Column field="conductorNombre" header="Conductor" style={{ minWidth: '8rem' }} />
                <Column field="busNumero" header="Bus" style={{ minWidth: '8rem' }}/>
                <Column field="fecha" header="Fecha" dataType='date' style={{ minWidth: '8rem' }} />
            </DataTable>
        </Container>
    );
};

export default ListaSalarios;