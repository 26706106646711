import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import React, { FC, useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { IAbono, IDeuda, IGastos, ILiquidacionDto } from "./Liquidaciones.type";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { formatDateForPost } from "../../util/DateUtil";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { axiosInstance } from "../../util/AxiosInterceptor";
import { createLiquidacion } from "../../redux/liquidaciones";
import { Message } from "primereact/message";

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

type Inputs = {
  fecha: Date | string;
  conductorDocumento: string;
  busNumero: string;
  viajes: number;
  regIni: number;
  regFin: number;
  tiquetes: number;
  sensor: number;
  civica: number;
  liquidada: boolean  ;
  gastos: IGastos[];
  abonoDto: IAbono;
  deudaDto: IDeuda;
  comentario: string;
};

const CrearLiquidacion: FC<Props> = ({ visible, setVisible }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    reset,
    setValue,
  } = useForm<Inputs>(
    {
      defaultValues: {
        liquidada: false,
        regIni: 0,
        regFin: 0,
        tiquetes: 0,
        sensor: 0,
        viajes: 0,
        civica: 0,
        gastos: [
          {
            nombre: "Aseo", valor: 0
          },
          {
            nombre: "Combustible", valor: 0
          },
        ],
        comentario: ""
      }
    }
  );

  const dispatch = useAppDispatch();
  
  const { listaConductores: conductores } = useAppSelector((state) => state.conductores);
  const { listaBuses: buses } = useAppSelector((state) => state.buses);

  const [error, setError] = useState<string>("");
  const [abono, setAbono] = useState<boolean>(false);
  const [deuda, setDeuda] = useState<boolean>(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "gastos",
  });

  const watchFecha = watch('fecha');
  const watchBus = watch('busNumero');
  const watchPasajerosReg = watch('regFin') - watch('regIni');

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const liquidacion = data as ILiquidacionDto;
    const fechaFormatted = formatDateForPost(liquidacion.fecha as Date);
    if(!deuda){
      liquidacion.deudaDto = undefined;
    }

    if(!abono){
      liquidacion.abonoDto = undefined;
    }

    if(liquidacion.abonoDto){
      liquidacion.abonoDto.fecha = fechaFormatted;
    }

    if(liquidacion.deudaDto){
      liquidacion.deudaDto.fecha = fechaFormatted;
    }

    liquidacion.fecha = formatDateForPost(liquidacion.fecha as Date);
    dispatch(createLiquidacion(liquidacion, setVisible));
  }

  useEffect(() => {
    const bus = buses.find(bus => bus.numero === watchBus)
    if(bus !== undefined){
      setValue('conductorDocumento', bus.conductorDocumento !== undefined ? bus.conductorDocumento : '');
    }
  }, [watchBus, setValue, buses]);

  const buscarLiquidacion = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    await axiosInstance
      .get('/liquidaciones/detalle', { params: { fecha: formatDateForPost(new Date(watchFecha)), busNumero: watchBus }})
      .then((response) => {
        const liquidacionCopy: ILiquidacionDto = response.data;
        if(liquidacionCopy.gastos !== undefined){
          liquidacionCopy.gastos = liquidacionCopy.gastos.length > 0 ? liquidacionCopy.gastos :
          [{ nombre: "Combustible", valor: 0 }, { nombre: "Aseo", valor: 0 }];     
        }
        if(liquidacionCopy.conductorDocumento === null && buses.length > 0){
          liquidacionCopy.conductorDocumento = buses.filter(bus => bus.numero === liquidacionCopy.busNumero)[0].conductorDocumento;
        }
    
        reset(liquidacionCopy);
        liquidacionCopy.abonoDto !== null ? setAbono(true) : setAbono(false);
        liquidacionCopy.deudaDto !== null ? setDeuda(true) : setDeuda(false);
        setValue('fecha', new Date(liquidacionCopy.fecha!));
        setError('');
      })
      .catch((error) => {
        setError(error.response.data.mensaje)
        reset({
          fecha: watchFecha,
          busNumero: watchBus,
          liquidada: false,
          regIni: 0,
          regFin: 0,
          tiquetes: 0,
          sensor: 0,
          viajes: 0,
          civica: 0,
          gastos: [
            {
              nombre: "Aseo", valor: 0
            },
            {
              nombre: "Combustible", valor: 0
            },
          ]
        })
      });
    
  }

  const getFormErrorMessage = (name: any) => {
    return errors[name as keyof Inputs] ? (
      <small className="p-error block">
        {errors[name as keyof Inputs]?.message}
      </small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <Dialog
      header="Formulario Liquidación"
      visible={visible}
      onHide={() => setVisible(false)}
      style={{ width: "60vw", maxWidth: "50rem" }}
    >
      {error ? <Message severity="error" text={error} /> : ''}

      <form
        id="form_liquidacion"
        className="formgrid grid"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="fecha"
          control={control}
          rules={{ required: "Fecha es requerida." }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Fecha
              </label>
              <Calendar
                inputId={field.name}
                value={field.value}
                onChange={field.onChange}
                dateFormat="dd/mm/yy"
                className={classNames({ "p-invalid": fieldState.error })}
                showIcon
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="busNumero"
          control={control}
          rules={{ required: "Bus es requerido." }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <p className="block" style={{marginBottom: '0.5rem'}}>
                Bus
              </p>
              <Dropdown
                id={field.name}
                value={field.value}
                placeholder="Seleccione un bus"
                options={buses}
                optionLabel="numero"
                optionValue="numero"
                filter
                focusInputRef={field.ref}
                onChange={(e) => field.onChange(e.value)}
                className={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="col-12 text-center">
          <Button
            className="my-2 w-20rem"
            label="Buscar"
            icon="pi pi-lens"
            disabled={watchBus === undefined || watchFecha === undefined}
            onClick={e => buscarLiquidacion(e)}
          />
        </div>
        <Controller
          name="conductorDocumento"
          control={control}
          rules={{ required: "Conductor es requerido." }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <p className="block" style={{marginBottom: '0.5rem'}}>
                Conductor
              </p>
              <Dropdown
                id={field.name}
                value={field.value}
                optionLabel="nombre"
                optionValue="documento"
                placeholder="Seleccione un conductor"
                filter
                options={conductores}
                focusInputRef={field.ref}
                onChange={(e) => field.onChange(e.value)}
                className={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="viajes"
          control={control}
          rules={{
            required: "Los viajes son requeridos.",
            validate: (value) =>
              value >= 0 || "Ingrese un número valido de viajes."
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Viajes
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                useGrouping={false}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="regFin"
          control={control}
          rules={{
            required: "La numeración final es requerida, puede ser 0.",
            validate: (value) =>
              (value >= 0 && value >= getValues().regIni) ||
              "Ingrese un número valido de registradora final.",
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Registradora Final
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="regIni"
          control={control}
          rules={{
            required: "La numeración inicial es requerida, puede ser 0.",
            validate: (value) =>
              value >= 0 || "Ingrese un número valido de registradora inicial.",
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Registradora Inicial
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Message className="col-12 py-2" text={`Pasajeros de registradora: ${watchPasajerosReg}`}/>
        <Controller
          name="sensor"
          control={control}
          rules={{
            required: "El sensor es requerido, puede ser 0.",
            validate: (value) =>
              value >= 0 || "Ingrese un número valido de sensor.",
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Sensor
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                useGrouping={false}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="civica"
          control={control}
          rules={{
            validate: (value) =>
              value >= 0 || "Ingrese un número valido de civica.",
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Civica
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                useGrouping={false}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="tiquetes"
          control={control}
          rules={{
            validate: (value) =>
              value >= 0 || "Ingrese un número valido de tiquetes.",
          }}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Tiquetes
              </label>
              <InputNumber
                id={field.name}
                inputRef={field.ref}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.value)}
                useGrouping={false}
                inputClassName={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="comentario"
          control={control}
          render={({ field, fieldState }) => (
            <div className="field col-6">
              <label htmlFor={field.name} className="block">
                Comentario
              </label>
              <InputText
                id={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e)}
                className={classNames({ "p-invalid": fieldState.error })}
              />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="col-12 surface-200" id="gastos_block">
          <div className="flex justify-content-start my-5 " id="gastos_actions">
            <Button
              className="mr-3"
              severity="info"
              onClick={() => append({ nombre: "", valor: 0 })}
              label="Agregar Gasto"
              raised
              type="button"
            />
            <Button
              className=""
              severity="danger"
              onClick={() => remove()}
              label="Limpiar Gastos"
              raised
              type="button"
            />
          </div>
          {fields.map((item, index) => (
            <div className="grid relative" key={item.id}>
              <Controller
                name={`gastos.${index}.nombre`}
                control={control}
                rules={{
                  required: "El nombre es requerido.",
                }}
                render={({ field, fieldState }) => (
                  <div className="field col-5">
                    <label htmlFor={field.name} className="block">
                      Nombre del Gasto
                    </label>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={classNames({ "p-invalid": fieldState.error })}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
              <Controller
                name={`gastos.${index}.valor`}
                control={control}
                rules={{
                  required: "El valor es requerido.",
                  validate: (value) =>
                    value! >= 0 || "Ingrese un número valido.",
                }}
                render={({ field, fieldState }) => (
                  <div className="field col-5">
                    <label htmlFor={field.name} className="block">
                      Valor del Gasto
                    </label>
                    <InputNumber
                      id={field.name}
                      inputRef={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={(e) => field.onChange(e.value)}
                      inputClassName={classNames({
                        "p-invalid": fieldState.error,
                      })}
                    />
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
              <Button
                type="button"
                className="absolute"
                style={{
                  top: "50%",
                  right: "10%",
                  transform: "translate(0, -35%)",
                }}
                severity="danger"
                onClick={() => remove(index)}
                icon="pi pi-times"
              />
            </div>
          ))}
        </div>

        <div className="col-12 my-5">
          <label htmlFor="abono" className="mr-2">
            Abono
          </label>
          <Checkbox
            inputId="abono"
            onChange={(e) => setAbono(e.checked!)}
            checked={abono}
          ></Checkbox>
          <label htmlFor="deuda" className="mx-2">
            Deuda
          </label>
          <Checkbox
            inputId="deuda"
            onChange={(e) => setDeuda(e.checked!)}
            checked={deuda}
          ></Checkbox>
        </div>

        {abono === true && (
          <Controller
            name="abonoDto.valor"
            control={control}
            rules={{
              required: "El valor del abono es requerido.",
              validate: (value) => value! >= 0 || "Ingrese un número valido.",
            }}
            render={({ field, fieldState }) => (
              <div className="field col-6">
                <label htmlFor={field.name} className="block">
                  Valor del Abono
                </label>
                <InputNumber
                  id={field.name}
                  inputRef={field.ref}
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={(e) => field.onChange(e.value)}
                  inputClassName={classNames({ "p-invalid": fieldState.error })}
                />
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        )}

        {deuda === true && (
          <>
            <Controller
              name="deudaDto.razon"
              control={control}
              rules={{
                required: "La razón de la deuda es requerida.",
              }}
              render={({ field, fieldState }) => (
                <div className="field col-6">
                  <label htmlFor={field.name} className="block">
                    Razón de deuda
                  </label>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />

            <Controller
              name="deudaDto.valor"
              control={control}
              rules={{
                required: "El valor de la deuda es requerida.",
                validate: (value) => value! >= 0 || "Ingrese un número valido.",
              }}
              render={({ field, fieldState }) => (
                <div className="field col-6">
                  <label htmlFor={field.name} className="block">
                    Valor de la Deuda
                  </label>
                  <InputNumber
                    id={field.name}
                    inputRef={field.ref}
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={(e) => field.onChange(e.value)}
                    inputClassName={classNames({
                      "p-invalid": fieldState.error,
                    })}
                  />
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </>
        )}

        <Controller
          name="liquidada"
          control={control}
          rules={{}}
          render={({ field, fieldState }) => (
            <div className="col-12">
              <label className='mr-3' htmlFor={field.name}>Liquidada</label>
              <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} className={classNames({ 'p-invalid mr-1': fieldState.error })} onChange={(e) => field.onChange(e.checked)} />
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="col-12">
          <Button
            className="mt-5 ml-2"
            label="Crear"
            type="submit"
            icon="pi pi-check"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default CrearLiquidacion;
