import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { IIngresoSensores, ILiquidacion, ILiquidacionDto } from '../../features/liquidaciones/Liquidaciones.type';
import { mostrarToast } from '../toast';
import { axiosInstance } from '../../util/AxiosInterceptor';
import { IMessage } from '../../shared/Message.type';
import { formatDateForPost } from '../../util/DateUtil';


export interface LiquidacionInitialState {
    liquidacion: ILiquidacionDto,
    liquidacionCreate: ILiquidacionDto,
    listaLiquidaciones: ILiquidacion[],
    loading: boolean,
    error: string | undefined
}

const initialState: LiquidacionInitialState = {
    liquidacion: {},
    liquidacionCreate: {},
    listaLiquidaciones: [],
    loading: false,
    error: undefined
}

export const liquidacionesSlice = createSlice({
    name : 'liquidaciones',
    initialState,
    reducers: {
        setLiquidacion: (state, action) =>{
            state.liquidacion = action.payload;
        },
        setListaLiquidaciones : (state, action) => {
            state.listaLiquidaciones = [...action.payload];
        },
        setLoading : (state, action) => {
            state.loading = action.payload;
        },
        setError : (state, action) => {
            state.error = action.payload;
        },
        pushToListaLiquidaciones: (state, action) => {
            state.listaLiquidaciones = state.listaLiquidaciones.find(item => item.uuid === action.payload.uuid) !== undefined ? 
            [action.payload, ...state.listaLiquidaciones.filter(item => item.uuid !== action.payload.uuid)]
             : [action.payload, ...state.listaLiquidaciones];
        },
        editItemListaLiquidaciones: (state, action) => {
            state.listaLiquidaciones = state.listaLiquidaciones.map(item => item.uuid === 
                action.payload.uuid ? action.payload : item);
        },
        pushArrayToListaLiquidaciones: (state, action) => {
            state.listaLiquidaciones = state.listaLiquidaciones.concat(...action.payload);
        },
        deleteFromListaLiquidaciones: (state, action) => {
            state.listaLiquidaciones = state.listaLiquidaciones.filter(item => item.uuid !== action.payload);
        },
        cleanLiquidacionForCreate: (state, action) => {
            state.liquidacionCreate = {};
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLiquidaciones.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLiquidaciones.fulfilled, (state, action) => {
                state.listaLiquidaciones = action.payload;
                state.loading = false;
            })
            .addCase(fetchLiquidaciones.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(fetchLiquidacionesFiltered.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchLiquidacionesFiltered.fulfilled, (state, action) => {
                state.listaLiquidaciones = action.payload;
                state.loading = false;
            })
            .addCase(fetchLiquidacionesFiltered.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(fetchDetalleLiquidacion.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchDetalleLiquidacion.fulfilled, (state, action) => {
                state.liquidacion = action.payload;
                state.loading = false;
            })
            .addCase(fetchDetalleLiquidacion.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(fetchDetalleLiquidacionCreate.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchDetalleLiquidacionCreate.fulfilled, (state, action) => {
                state.liquidacionCreate = action.payload;
                state.loading = false;
            })
            .addCase(fetchDetalleLiquidacionCreate.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
    }
});

export const { setLiquidacion, setListaLiquidaciones,
     setLoading, setError, pushToListaLiquidaciones, 
     editItemListaLiquidaciones, pushArrayToListaLiquidaciones, deleteFromListaLiquidaciones, cleanLiquidacionForCreate } = liquidacionesSlice.actions;

export default liquidacionesSlice.reducer;

export const fetchLiquidaciones = createAsyncThunk('liquidaciones/fetchLiquidaciones', async () => {
    const response = await axiosInstance.get('/liquidaciones/table');
    return response.data;
});

export const fetchDetalleLiquidacionCreate = createAsyncThunk('liquidaciones/fetchDetalleLiquidacionCreate', async({fecha, busNumero} 
    : {fecha: Date | string, busNumero: string}) => {
    const response = await axiosInstance.get('/liquidaciones/detalle', { params: { fecha, busNumero }});
    return response.data;
});

export const fetchDetalleLiquidacion = createAsyncThunk('liquidaciones/fetchDetalleLiquidacion', async (uuid: string) => {
    const response = await axiosInstance.get('/liquidaciones/detalle', { params: { uuid }});
    return response.data;
});

export const fetchLiquidacionesFiltered = createAsyncThunk('liquidaciones/fetchLiquidacionesFiltered', async ({busNumero, conductorDocumento, fechaInicio, fechaFinal, liquidada} 
    : {busNumero: string, conductorDocumento: string, fechaInicio: Date | string, fechaFinal: Date | string, liquidada: boolean}) => {
    
    const conductor = conductorDocumento === '' || conductorDocumento === null ? null : conductorDocumento;
    const bus = busNumero === '' || busNumero === null ? null : busNumero;
    const fechaIni = fechaInicio === '' || fechaInicio === null ? null : formatDateForPost(new Date(fechaInicio));
    const fechaFin = fechaFinal === '' || fechaFinal === null ? null : formatDateForPost(new Date(fechaFinal));
    const response = await axiosInstance.get('/liquidaciones/v2/table', 
        { params : {
            fechaInicial: fechaIni,
            fechaFinal: fechaFin,
            numero: bus,
            documento: conductor,
            liquidada: liquidada
        }}
    );
    return response.data;
});

export const createLiquidacion = (payload: ILiquidacionDto, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .post('/liquidaciones', payload)
    .then((response) => {
        const liquidacion = response?.data as ILiquidacion;
        dispatch(pushToListaLiquidaciones(liquidacion));
        dispatch(mostrarToast({severity: 'success', summary: '', detail: 'Creado exitosamente'}))
        setVisible(false)
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const editarLiquidacion = (payload: ILiquidacionDto, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .put('/liquidaciones/liquidar', payload)
    .then((response) => {
        const liquidacion = response?.data as ILiquidacion;
        dispatch(editItemListaLiquidaciones(liquidacion));
        dispatch(mostrarToast({severity: 'success', summary: '', detail: 'Creado exitosamente'}))
        setVisible(false)
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const ingresarSensores = (payload: IIngresoSensores[], setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .post('/liquidaciones/sensores', payload)
    .then((response) => {
        const liquidaciones = response?.data as ILiquidacion[];
        dispatch(pushArrayToListaLiquidaciones(liquidaciones));
        setVisible(false)
        dispatch(mostrarToast({severity: 'success', summary: '', detail: 'Sensores ingresados'}))
    })
    .catch((error) => {
       dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const eliminarLiquidacion = (uuid: string, setVisible: (value: boolean) => void) => (dispatch: any) => {
    axiosInstance
    .delete('/liquidaciones', { params: { uuid }})
    .then((response) => {
        const mensaje = response?.data as IMessage;
        dispatch(deleteFromListaLiquidaciones(uuid));
        setVisible(false);
        dispatch(mostrarToast({severity: 'success', summary: '', detail: mensaje.mensaje}))
    })
    .catch((error) => {
        dispatch(mostrarToast({severity: 'error', summary: '', detail: error.response.data.mensaje}))
    });
};

export const cleanLiquidacionCreate = () => (dispatch: any) =>{
    dispatch(cleanLiquidacionForCreate);
};